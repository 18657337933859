.SBRMDrawerWrapper {
  display: flex;
  height: 100%;
}

.SBRMRightPanelWrapper {
  flex: 0 0 600px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.SBRMFormWrapper {
  flex: 1;
  min-width: 0;
  min-height: 0;
  overflow: auto;
  padding: 24px;
}

.SBRMFooterWrapper {
  flex-shrink: 0;
  padding: 8px 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  background: #202020;
}

.SBRMFileViewerWrapper {
  flex: 1;
  height: calc(100vh - 65px);
  overflow: scroll;
}
